import React from "react";
import { UserRoles } from "./common/types/types";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

import withSuspense from "./components/withSuspense/withSuspense";

const Registration = withSuspense(
  React.lazy(async () => await import("./pages/Registration/Registration"))
);
const VerifyEmail = withSuspense(
  React.lazy(async () => await import("./pages/VerifyEmail/VerifyEmail"))
);

const UserSettings = withSuspense(
  React.lazy(async () => await import("./pages/UserSettings/UserSettings"))
);

const CompanySettings = withSuspense(
  React.lazy(
    async () => await import("./pages/CompanySettings/CompanySettings")
  )
);

const Login = withSuspense(
  React.lazy(async () => await import("./pages/Login/Login"))
);

const Dashboard = withSuspense(
  React.lazy(async () => await import("./pages/Dashboard/Dashboard"))
);
const SystemDashboard = withSuspense(
  React.lazy(
    async () => await import("./pages/SystemDashboard/SystemDashboard")
  )
);

const Overview = withSuspense(
  React.lazy(async () => await import("./pages/Overview/Overview"))
);

const ProblemTypes = withSuspense(
  React.lazy(async () => await import("./pages/ProblemTypes/ProblemTypes"))
);

const ProblemType = withSuspense(
  React.lazy(
    async () => await import("./pages/ProblemTypes/ProblemType/ProblemType")
  )
);

const Sectors = withSuspense(
  React.lazy(async () => await import("./pages/Sectors/Sectors"))
);

const Sector = withSuspense(
  React.lazy(async () => await import("./pages/Sectors/Sector/Sector"))
);

const Tasks = withSuspense(
  React.lazy(async () => await import("./pages/Tasks/Tasks"))
);

const Posts = withSuspense(
  React.lazy(async () => await import("./pages/Posts/Posts"))
);
const Post = withSuspense(
  React.lazy(async () => await import("./pages/Posts/Post/Post"))
);
const Task = withSuspense(
  React.lazy(async () => await import("./pages/Tasks/Task/Task"))
);

const AreasAndLocations = withSuspense(
  React.lazy(
    async () => await import("./pages/AreasAndLocations/AreasAndLocations")
  )
);
const Area = withSuspense(
  React.lazy(
    async () => await import("./pages/AreasAndLocations/Areas/Area/Area")
  )
);
const Location = withSuspense(
  React.lazy(
    async () =>
      await import("./pages/AreasAndLocations/Locations/Location/Location")
  )
);
const Users = withSuspense(
  React.lazy(async () => await import("./pages/Users/Users"))
);

const User = withSuspense(
  React.lazy(async () => await import("./pages/Users/User/User"))
);

const Companies = withSuspense(
  React.lazy(async () => await import("./pages/Companies/Companies"))
);

const Company = withSuspense(
  React.lazy(async () => await import("./pages/Companies/Company/Company"))
);

const ForgotPassword = withSuspense(
  React.lazy(async () => await import("./pages/ForgotPassword/ForgotPassword"))
);

const ResetPassword = withSuspense(
  React.lazy(async () => await import("./pages/ForgotPassword/ResetPassword"))
);

const ChangePassword = withSuspense(
  React.lazy(async () => await import("./pages/ChangePassword/ChangePassword"))
);

const useRoutesList = () => {
  const routesList = [
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute
          availableForRoles={[
            UserRoles.companyAdmin,
            UserRoles.reporter,
            UserRoles.worker,
          ]}
        >
          <Dashboard />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "overview",
          element: <Overview />,
        },
        {
          path: "problemTypes",
          children: [
            {
              element: <ProblemTypes />,
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: <ProblemType />,
                  index: true,
                },
              ],
            },
          ],
        },
        {
          path: "sectors",
          children: [
            {
              element: <Sectors />,
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: <Sector />,
                  index: true,
                },
              ],
            },
          ],
        },
        {
          path: "tasks",
          children: [
            {
              element: <Tasks />,
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: <Task />,
                  index: true,
                },
              ],
            },
          ],
        },
        {
          path: "posts",
          children: [
            {
              element: <Posts />,
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: <Post />,
                  index: true,
                },
              ],
            },
          ],
        },
        {
          path: "areas",
          children: [
            {
              element: <AreasAndLocations />,
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: <Area />,
                  index: true,
                },
              ],
            },
            {
              path: ":id/location/:id",
              children: [
                {
                  element: <Location />,
                  index: true,
                },
              ],
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              element: (
                <ProtectedRoute availableForRoles={[UserRoles.companyAdmin]}>
                  <Users />
                </ProtectedRoute>
              ),
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: (
                    <ProtectedRoute
                      availableForRoles={[UserRoles.companyAdmin]}
                    >
                      <User />
                    </ProtectedRoute>
                  ),
                  index: true,
                },
              ],
            },
          ],
        },
        {
          path: "myUserSettings",
          element: <UserSettings />,
        },
        {
          path: "companySettings",
          element: <CompanySettings />,
        },
        {
          element: <ChangePassword />,
          path: "changePassword",
        },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Registration />,
    },
    {
      path: "/resetPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/changePassword", //TODO - adjust routes after BE sorts out verification link
      element: <ResetPassword />,
    },
    {
      path: "/verifyEmail",
      element: <VerifyEmail />,
    },
    {
      path: "/resetPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/forgotPassword", //TODO - adjust routes after BE sorts out verification link
      element: <ResetPassword />,
    },
    {
      path: "/systemDashboard",
      element: (
        <ProtectedRoute availableForRoles={[UserRoles.systemAdmin]}>
          <SystemDashboard />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "myUserSettings",
          element: <UserSettings />,
        },
        {
          element: <ChangePassword />,
          path: "changePassword",
        },
        {
          path: "companies",
          children: [
            {
              element: (
                <ProtectedRoute availableForRoles={[UserRoles.systemAdmin]}>
                  <Companies />
                </ProtectedRoute>
              ),
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: <Company />,
                  index: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return routesList;
};

export default useRoutesList;
