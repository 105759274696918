export const spacing = {
  n: "2px",
  n2: "4px",
  n4: "8px",
  n6: "12px",
  n8: "16px",
  n10: "20px",
  n12: "24px",
  n14: "28px",
  n16: "32px",
  n18: "36px",
  n20: "40px",
  n22: "44px",
  n24: "48px",
  n26: "52px",
  n28: "56px",
  n30: "60px",
  n32: "64px",
  n40: "80px",
};
