import React, { ComponentType, SuspenseProps } from "react";

import Loader from "../Loader/Loader";

function withSuspense<T extends object>(
  WrappedComponent: ComponentType<T> & any,
  fallback: SuspenseProps["fallback"] = <Loader />
) {
  function ComponentWithSuspense() {
    return (
      <React.Suspense fallback={fallback}>
        <WrappedComponent />
      </React.Suspense>
    );
  }

  return ComponentWithSuspense;
}
export default withSuspense;
