export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  primary: "#4065AD",
  text: {
    dark: "#393939",
    light: "#636363",
    disabled: "#BFBFBF",
    white: "#FFFFFF",
  },
  p1: {
    100: "#E3E8F3",
    200: "#C2CDE5",
    300: "#90A6CF",
    400: "#4065AD",
    500: "#35589A",
    600: "#315292",
    700: "#294A8A",
    800: "#1F4080",
    900: "#163570",
  },
  accent: {
    a1: "#7640AD",
    a2: "#B8CAE3",
    a3: "#A5F402",
    a4: "#D1F22F",
    a5: "#DEF203",
    a6: "#E7DD0C",
    a7: "#B475B1",
    a8: "#FF8C8C",
    a9: "#F3C347",
  },
  neutrals: {
    n10: "#FDFDFD",
    n20: "#FBFBFB",
    n30: "#F9F9F9",
    n50: "#FAFAFA",
    n100: "#F0F0F0",
    n150: "#E6E6E6",
    n200: "#DBDBDB",
    n250: "#D1D1D1",
    n300: "#C7C7C7",
    n350: "#BDBDBD",
    n400: "#B3B3B3",
    n450: "#A8A8A8",
    n500: "#9E9E9E",
    n550: "#949494",
    n600: "#8A8A8A",
    n650: "#808080",
    n700: "#757575",
    n750: "#6B6B6B",
    n800: "#616161",
    n850: "#565656",
    n900: "#4D4D4D",
    n950: "#434343",
    n1000: "#393939",
  },
  info: {
    50: "#E3E8F3",
    100: "#90A6CF",
    400: "#4065AD",
    700: "#315294",
    800: "#15316A",
  },
  error: {
    50: "#F3E3E6",
    100: "#CF909B",
    400: "#AD4054",
    700: "#8F2D3F",
    800: "#7B1A2C",
  },
  warning: {
    50: "#F3EFE3",
    100: "#CFC190",
    400: "#AD9540",
    700: "#79682B",
    800: "#51420F",
  },
  success: {
    50: "#E3F3E4",
    100: "#90CF96",
    400: "#40AD4B",
    700: "#287A30",
    800: "#124B17",
  },
};
