import { mergeStyleSets } from "@fluentui/react";

import { theme } from "../../styles/theme";

export interface ComponentClassNames {
  loaderWrapper: string;
  loader: string;
}

export const getClassNames: ComponentClassNames = mergeStyleSets({
  loaderWrapper: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    backgroundColor: theme.palette.white,
    opacity: 0.3,
  },
  loader: {
    root: {
      position: "relative",
    },
    ".ms-Spinner-circle": {
      height: "50px",
      width: "50px",
    },
  },
});
