import { mergeStyleSets } from "@fluentui/react";
import { typography, spacing } from "../../styles/variables";
interface ComponentClassNames {
  toaster: string;
}

export const getClassNames: ComponentClassNames = mergeStyleSets({
  toaster: {
    ".Toastify__toast": {
      fontFamily: typography.fonts.primary,
      minWidth: "350px",
      borderRadius: "12px",
      boxShadow: " 0px 4px 50px rgba(0, 0, 0, 0.08)",
      padding: spacing.n8,
      ".Toastify__toast-body": {
        padding: "29px 0",
      },
      ".Toastify__progress-bar": {
        height: "12px",
      },
    },
  },
});
