import "react-toastify/dist/ReactToastify.css";

import { useMemo } from "react";
import { useRoutes } from "react-router-dom";

import useIcons from "./hooks/useIcons";
import useUser from "./hooks/useUser";
import useRoutesList from "./routes";

import Toaster from "./components/Toaster/Toaster";

import UserContext from "./context";

function App() {
  const routesList = useRoutesList();
  const routes = useRoutes(routesList);
  const userCtx = useUser();

  useMemo(useIcons, [useIcons]);

  return (
    //access UserContext values/methods from useUsers()
    <UserContext.Provider value={userCtx}>
      <div className="App">{routes}</div>
      <Toaster />
    </UserContext.Provider>
  );
}
export default App;
