import { ProtectedRouteProps } from "./interfaces";
import { Navigate } from "react-router-dom";

import useUser from "../../hooks/useUser";

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { availableForRoles, children } = props;
  const { user } = useUser();

  let isAvailableForUser = false;

  availableForRoles.forEach((userRole) => {
    if (user.isUserRole(userRole)) {
      isAvailableForUser = true;
    }
  });

  if (!isAvailableForUser) {
    return <Navigate to="/" replace />;
  }

  return children;
}
