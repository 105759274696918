import { ToastContainer } from "react-toastify";

import { getClassNames } from "./Toaster.styles";

const { toaster } = getClassNames;

function Toaster() {
  return (
    <ToastContainer
      className={toaster}
      position="bottom-left"
      autoClose={3000}
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      theme="light"
    />
  );
}

export default Toaster;
