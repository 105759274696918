const set = (key: string, value: any) => {
  localStorage.setItem(key, String(value));
};

const get = (key: string): string => {
  return localStorage.getItem(key) as string;
};

const remove = (key: string) => {
  localStorage.removeItem(key);
};

const clearAll = () => {
  localStorage.clear();
};

const Storage = {
  set,
  get,
  remove,
  clearAll,
};

export default Storage;
