import { createTheme, Theme } from "@fluentui/react";
import { colors, spacing, typography } from "./variables";
import "./base/index.css";

export const theme: Theme = createTheme({
  defaultFontStyle: {
    fontSize: "13px",
    fontWeight: typography.weights.regular,
    fontFamily: typography.fonts.primary,
  },
  fonts: {
    small: {
      fontSize: "13px",
      lineHeight: typography.lineHeights.small,
    },
    medium: {
      fontSize: "16px",
      lineHeight: typography.lineHeights.medium,
    },
    large: {
      fontSize: "20px",
      lineHeight: typography.lineHeights.medium,
    },
  },
  palette: {
    themePrimary: colors.primary,
    purpleLight: colors.accent.a7,
    purple: colors.accent.a1,
    blueLight: colors.accent.a2,
    green: colors.accent.a3,
    greenLight: colors.accent.a4,
    yellowLight: colors.accent.a5,
    yellow: colors.accent.a6,
    yellowDark: colors.accent.a9,
    orangeLight: colors.accent.a8,
    white: colors.white,
    black: colors.black,
  },
  semanticColors: {
    bodyBackground: colors.white,
    bodyStandoutBackground: colors.neutrals.n30,
    bodyText: colors.text.dark,
    errorText: colors.error[400],
    errorBackground: colors.error[50],
    errorIcon: colors.error[400],
    warningBackground: colors.warning[50],
    infoBackground: colors.info[50],
    inputBorder: "#C9C9C9",
    inputBorderHovered: "#838383",
    inputText: colors.text.dark,
    buttonBackground: "#F3F3F3",
    buttonBackgroundHovered: "#E8E8E8",
    buttonBackgroundPressed: "#DDDDDD",
    buttonBackgroundDisabled: "#F3F3F3",
    primaryButtonBackgroundHovered: colors.p1[500],
    primaryButtonBackgroundPressed: colors.p1[600],
    primaryButtonBackgroundDisabled: colors.p1[200],
  },
  components: {
    PrimaryButton: {
      styles: {
        root: {
          "&.is-disabled": {
            color: colors.text.white,
          },
          "i svg path": {
            fill: `${colors.white}!important`,
          },
        },
      },
    },
    DefaultButton: {
      //Secondary button, Primary button inherits DefaultButton styles
      styles: {
        root: {
          padding: "10px 16px",
          borderRadius: "12px",
          height: "unset",
          fontSize: "16px",
          borderColor: "transparent",
          ".ms-Button-label": {
            margin: "unset",
            lineHeight: typography.lineHeights.medium,
            fontWeight: typography.weights.bold,
          },
          "&:focus::after": {
            inset: "-1px!important",
            border: `2px solid ${colors.text.dark}!important`,
            borderRadius: "12px",
          },
          "&.is-disabled": {
            color: colors.text.disabled,
          },
          "i svg path": {
            fill: `${colors.black}!important`,
          },
        },
      },
    },
    ActionButton: {
      styles: {
        root: {
          lineHeight: typography.lineHeights.medium,
          ":hover": {
            color: colors.p1[800],
          },
          "&.is-disabled": {
            color: colors.text.disabled,
          },
          "i svg path": {
            fill: `${colors.black}!important`,
          },
        },
      },
    },
    TextField: {
      styles: {
        root: {
          width: "100%",
          "@media(min-width: 960px)": {
            width: 516,
          },
          ".ms-TextField-fieldGroup, .ms-TextField-field": {
            borderRadius: "12px",
            height: "24px!important",
            backgroundColor: colors.white,
            input: {
              borderRadius: "unset",
            },
          },

          label: {
            fontWeight: typography.weights.bold,
            paddingBottom: spacing.n,
            paddingTop: "unset",
          },
          ".ms-TextField-fieldGroup": {
            height: "unset",
            padding: "6px 10px",
            "::after": {
              borderRadius: "12px",
            },
            ":active": {
              borderColor: colors.primary,
            },
          },
          ".ms-TextField-errorMessage": {
            fontWeight: typography.weights.bold,
          },
          ".ms-TextField-reveal": {
            height: "24px!important",
          },
          ".ms-TextField-reveal:hover": {
            backgroundColor: "transparent",
          },
          ".ms-TextField-field": {
            padding: "unset",
            borderRadius: "unset",
            "&.is-disabled": {
              label: {
                color: "initial",
              },
            },
          },
        },
      },
    },
    ComboBox: {
      styles: {
        root: {
          height: "unset",
          padding: "8px 10px",
          backgroundColor: "white",
          position: "relative",
          "::after": {
            borderRadius: "12px",
          },
          ":active": {
            borderColor: colors.primary,
          },
          borderRadius: "12px",
          "&.is-disabled": {
            backgroundColor: "white",
            "::after": {
              borderRadius: "12px",
            },
            button: {
              visibility: "hidden",
            },
          },
          input: {
            borderRadius: "unset",
            backgroundColor: "white",
          },
          button: {
            backgroundColor: "white",
            position: "absolute",
            right: "0",
            ":hover": {
              backgroundColor: "white",
              cursor: "pointer",
            },
          },
        },
      },
    },
    Dropdown: {
      styles: {
        root: {
          ".ms-Dropdown": {
            border: "1px solid #C9C9C9",
            padding: "6px 10px",
            backgroundColor: "white",
            position: "relative",
            ":focus::after": {
              borderRadius: "12px",
              borderColor: "transparent",
            },
            ":focus": {
              borderColor: colors.primary,
            },
            ":active": {
              borderColor: colors.primary,
            },
            borderRadius: "12px",
          },
          ".readonly": {
            backgroundColor: "white",
            "::after": {
              borderRadius: "12px",
            },
            ".ms-Dropdown-caretDownWrapper": {
              visibility: "hidden",
              height: "24px",
            },
          },
          ".ms-Dropdown-title": {
            border: "none",
            padding: 0,
            height: "25px",
            backgroundColor: "white",
            color: "initial!important",
          },
          label: {
            fontWeight: typography.weights.bold,
          },
          ".ms-Dropdown-callout": {
            boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.08)!important",
            borderRadius: "12px",
          },
        },
      },
    },
    Layer: {
      styles: {
        root: {
          ".ms-Callout": {
            boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.08)!important",
            borderRadius: "12px",
          },
          ".ms-ContextualMenu-list": {
            li: {
              padding: spacing.n2,
              button: {
                borderRadius: "6px",
              },
            },
          },
        },
      },
    },
    Modal: {
      styles: {
        root: {
          ".ms-Modal-scrollableContent": {
            overflowY: "unset",
          },
        },
      },
    },
  },
});
