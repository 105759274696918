import {
  unregisterIcons,
  registerIcons,
  initializeIcons,
} from "@fluentui/react";

import {
  PasswordHide,
  PasswordReveal,
  ChevronDown,
  Save,
  Cancel,
  ChevronLeft,
  ChevronRight,
  SidebarToggle,
  DashboardIcon,
  TasksIcon,
  ProblemTypesIcon,
  AreasAndLocationsIcon,
  UsersIcon,
  CompanySettingsIcon,
  UserSettings,
  LogOut,
  Edit,
  Delete,
  Upload,
  User,
  Filter,
  Company,
  Search,
  Audio,
  DownloadFile,
  File,
  Play,
  Video,
  Pause,
  Stop,
  Inprogress,
  All,
  Overdue,
  Finished,
  Todo,
  Paused,
} from "../assets/icons/index";

function useIcons() {
  initializeIcons();

  unregisterIcons([
    "RedEye",
    "hide",
    "ChevronDown",
    "Save",
    "Cancel",
    "ChevronLeft",
    "ChevronRight",
    "DoubleChevronLeft",
    "Chart",
    "SingleColumnEdit",
    "MuteChat",
    "MapPin",
    "People",
    "Settings",
    "PlayerSettings",
    "PowerButton",
    "Edit",
    "Delete",
    "CloudUpload",
    "User",
    "Filter",
    "Teamwork",
    "Search",
    "MusicInCollection",
    "Download",
    "ReadingMode",
    "Play",
    "Video",
    "Stop",
    "Pause",
    "ProgressLoopInner",
    "TaskSolid",
    "SyncOccurenceCancel",
    "Completed12",
    "TaskList",
    "ProcessingPause",
  ]);

  registerIcons({
    icons: {
      RedEye: <PasswordHide />,
      hide: <PasswordReveal />,
      ChevronDown: <ChevronDown />,
      Save: <Save />,
      Cancel: <Cancel />,
      ChevronRight: <ChevronRight />,
      ChevronLeft: <ChevronLeft />,
      DoubleChevronLeft: <SidebarToggle />,
      Chart: <DashboardIcon />,
      SingleColumnEdit: <TasksIcon />,
      MuteChat: <ProblemTypesIcon />,
      MapPin: <AreasAndLocationsIcon />,
      People: <UsersIcon />,
      Settings: <CompanySettingsIcon />,
      PlayerSettings: <UserSettings />,
      PowerButton: <LogOut />,
      Edit: <Edit />,
      Delete: <Delete />,
      CloudUpload: <Upload />,
      User: <User />,
      Filter: <Filter />,
      Teamwork: <Company />,
      Search: <Search />,
      MusicInCollection: <Audio />,
      Download: <DownloadFile />,
      ReadingMode: <File />,
      Play: <Play />,
      Video: <Video />,
      Pause: <Pause />,
      Stop: <Stop />,
      ProgressLoopInner: <Inprogress />,
      TaskSolid: <All />,
      SyncOccurenceCancel: <Overdue />,
      Completed12: <Finished />,
      TaskList: <Todo />,
      ProcessingPause: <Paused />,
    },
  });
}

export default useIcons;
