import { createContext } from "react";

import { UserDto } from "./api/data-contracts";

interface UserCtx {
  user: UserDto;
  setUser: (user: UserDto) => void;
}

const UserContext = createContext<UserCtx>({
  user: {},
  setUser: (user: UserDto) => user,
});

export default UserContext;
