export const typography = {
  fonts: {
    primary: "Open Sans",
    secondary: "Signika",
  },
  weights: {
    regular: "400",
    medium: "600",
    bold: "700",
  },
  lineHeights: {
    small: "20px",
    medium: "24px",
    large: "28px",
  },
};
