import { useContext, useEffect, useMemo, useState } from "react";
import { UserDto } from "../api/data-contracts";
import { UserRoleValues, UserWithRoleCheck } from "../common/types";
import UserContext from "../context";
import Storage from "../services/Storage";

function useUser() {
  const storedUser = useMemo(() => JSON.parse(Storage.get("user")), []);

  const [user, setUser] = useState<UserDto>(storedUser ?? {});
  const { user: userContext, setUser: setUserContext } =
    useContext(UserContext);

  const logout = () => {
    setUser({});
    Storage.remove("authToken");
    Storage.remove("refreshToken");
    Storage.remove("authTokenExpiresAt");
    Storage.remove("user");
  };

  useEffect(() => {
    Storage.set("user", JSON.stringify(user));
    setUserContext(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const userWithRoleFlags: UserWithRoleCheck = {
    ...user,
    isUserRole: (userRole: UserRoleValues): boolean =>
      user.userRoleId === userRole,
  };

  return { user: userWithRoleFlags, userContext, setUser, logout };
}

export default useUser;
