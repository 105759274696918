import { IStackProps, Spinner, Stack } from "@fluentui/react";

import { getClassNames } from "./Loader.styles";

const { loaderWrapper, loader } = getClassNames;

export const Loader = () => {
  const rowProps: IStackProps = {
    horizontal: true,
    verticalAlign: "stretch",
    horizontalAlign: "center",
  };
  const tokens = {
    sectionStack: { childrenGap: 10 },
    loaderStack: { childrenGap: 20 },
  };

  return (
    <>
      <Stack
        {...rowProps}
        tokens={tokens.loaderStack}
        className={loaderWrapper}
      >
        <Spinner className={loader} />
      </Stack>
    </>
  );
};

export default Loader;
