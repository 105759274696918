import { UserDto } from "../../api/data-contracts";
import { FormComponentConfig } from "../interfaces";

export type ValuesArray<T> = T[keyof T];

export type ApiServiceCall = (args?: any) => Promise<any>; // TODO fix

export type HookFormConfig = Record<string, FormComponentConfig>;

export enum FieldTypes {
  string = "string",
  date = "date",
  number = "number",
  lookup = "lookup",
  list = "list",
  search = "search",
}

export enum FormMode {
  add = "add",
  edit = "edit",
}

export enum UserRoles {
  reporter = "25d3852b-1311-4d7a-7e0f-08dae7e60903",
  worker = "a0a3fb9f-7961-468d-21b3-08dae7e63834",
  companyAdmin = "6ab87f80-2de2-4f95-bce5-7b86f38e426f",
  systemAdmin = "2bb6c88d-6cf0-4e3b-80a1-fff11651c00f",
}

export type UserRoleValues = ValuesArray<typeof UserRoles>;

export type UserWithRoleCheck = UserDto & {
  isUserRole: (userRole: UserRoleValues) => boolean;
};
